import React from 'react'
import { SEO } from '../components/UI'
import { InternalHeader, InternalContent, InternalTestimonial, InternalPresentation } from '../components/Internal'
import styles from './politica-de-privacidade.module.css'
export default function Page() {
  return (
    <>
      <SEO title='Uso, Segurança e Privacidade' />
      <InternalHeader>
        <h1>
          Uso, Segurança
          <br />e <strong>Privacidade</strong>
        </h1>
      </InternalHeader>
      <section className={styles.container}>
        <div className={styles.content}>
          <InternalContent className={styles.terapyContent}>
            <h2>
              Nossa <strong>política de privacidade</strong>
            </h2>
            <p>
              O portal Lu Moura, não divulga sob nenhuma hipótese qualquer mensagem trocada entre as partes envolvidas.
              Sendo de total sigilo as informações entre paciente e terapeuta. Os e-mails cadastrados em nosso mailing
              não são repassados a terceiros ou de uso comercial.
            </p>

            <h2>
              Segurança da <strong>informação</strong>
            </h2>
            <p>
              O portal Lu Moura, utiliza ferramentas de terceiros para realizar as consultas online e ensino à
              distância, logo, não utiliza ou mantém ferramenta proprietária. Todas as informações trocadas ou gravadas
              através das plataformas de ensino ou comunicação, são de total responsabilidade das empresas parceiras de
              manter e garantir a segurança da informação assim como a privacidade de seus clientes e parceiros.
            </p>
            <p>
              A Lu Moura não envia e-mails ou mensagens para nenhum dispositivo solicitando dados pessoais ou de
              pagamentos.O portal Lu Moura, não divulga sob nenhuma hipótese qualquer mensagem trocada entre as partes
              envolvidas. Sendo de total sigilo as informações entre paciente e terapeuta. Os e-mails cadastrados em
              nosso mailing não são repassados a terceiros ou de uso comercial.
            </p>

            <h2>
              Termos de <strong>uso</strong>
            </h2>
            <p>
              Todo o conteúdo de nosso portal são registros reais de nossas atividades e fruto do nosso trabalho. Logo,
              caso tenha algum material publicado, seja em forma de texto ou imagem, que possa constranger ou ferir de
              alguma forma, basta entrar em contato conosco e removeremos o mesmo do nosso website.
            </p>
            <p>Proibida a cópia total ou parcial de qualquer conteúdo sem a plena autorização da Lu Moura.</p>
          </InternalContent>
          <div className='aside'>
            <InternalPresentation />
            <InternalTestimonial className={styles.testimonial} />
          </div>
        </div>
      </section>
    </>
  )
}
